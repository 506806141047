import posthog from "posthog-js";
import { dfmAlert } from "~/public_html/dfm5";

export async function getCourse(coid) {
  try {
    const response = await fetch(`/api/course/course/${coid}`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const unit = await response.json();
    return unit;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving this course.");
  }
}

export async function getUnit(cuid, includeInactiveSubskills = true) {
  try {
    const url = includeInactiveSubskills ? `/api/course/explore/${cuid}` : `/api/course/unit/${cuid}`
    const response = await fetch(url);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const unit = await response.json();
    return unit;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving this unit.");
  }
}

export async function getSubskillPathWithinCourse(coid, ssid) {
  try {
    const response = await fetch(`/api/course/course/${coid}/subskillpath/${ssid}`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const unit = await response.json();
    return unit;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving the path.");
  }
}

export async function getAllSkillsCourseDetails() {
  try {
    const response = await fetch(`/api/course/course/allskills/details`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const course = await response.json();
    return course;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving the all skills course.");
  }
}

export async function getUserCourses(uid) {
  try {
    const response = await fetch(`/api/user/${uid}/courses`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    return courses;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving your user courses.");
  }
}

export async function getSchoolCourses(sid) {
  try {
    const response = await fetch("/api/course/school/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sid }),
    });
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    return courses;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving your school courses.");
  }
}

export async function getPublishedCourses() {
  try {
    const response = await fetch("/api/courses/published");
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const authors = await response.json();
    return authors;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving the published course selection.");
  }
}

export async function getCourseGroupsForDropdown({
  user = null,
  preselectedCourse = null,
} = {}) {
  // Get the 'All Skills' course that will display for all users.
  const allSkillsCourse = await getAllSkillsCourseDetails();
  const globalCourses = allSkillsCourse ? [allSkillsCourse] : [];

  // If there is a logged in user, get their selection of courses.
  const uid = user?.uid;
  const userCourses = uid ? await getUserCourses(uid) : [];
  const userCoursesFiltered = userCourses?.filter((course) => {
    const inGlobalCourses = globalCourses.some((c) => c.coid === course.coid);
    return !inGlobalCourses;
  }) ?? [];

  // If they belong to a school, get the school selection of active courses, without duplicates of above.
  const sid = user?.sid;
  const schoolCourses = sid ? await getSchoolCourses(sid) : [];
  const schoolCoursesFiltered = schoolCourses?.filter((course) => {
    const inGlobalCourses = globalCourses.some((c) => c.coid === course.coid);
    const inUserCourses = userCoursesFiltered.some(
      (c) => c.coid === course.coid,
    );
    const isActive = course.status === 1 || course.status === 3;
    return !inGlobalCourses && !inUserCourses && isActive;
  }) ?? [];

  // If a preselected course has been passed to this function, check it isn't already included above.
  const allCoursesSoFar = [
    ...globalCourses,
    ...userCoursesFiltered,
    ...schoolCoursesFiltered,
  ];
  const currentlyViewingCourse =
    preselectedCourse &&
    !allCoursesSoFar.some((c) => c.coid === preselectedCourse.coid)
      ? preselectedCourse
      : null;

  // Set a link for the school courses section if the user type is appropriate.
  const canModifySchoolCourses = ["teacher", "trust", "admin"].some(
    (role) => role === user?.type,
  );
  const schoolCoursesLink = canModifySchoolCourses
    ? {
        label: "Manage school courses",
        href: posthog.isFeatureEnabled("courses-interface-p1") ? "/manage-courses.php" : "/courses.php"
      }
    : undefined;

  // Set up the course groups for the dropdown menu.
  const courseGroups = [
    {
      id: "current",
      heading: "Currently viewing",
      courses: currentlyViewingCourse ? [currentlyViewingCourse] : [],
    },
    {
      id: "user",
      heading: "Your courses",
      courses: userCoursesFiltered,
    },
    {
      id: "school",
      heading: "School courses",
      courses: schoolCoursesFiltered,
      link: schoolCoursesLink,
    },
    {
      id: "all",
      heading: "All skills",
      courses: globalCourses,
    },
  ];

  return courseGroups;
}

export async function getResourceCountForCourse(coid) {
  try {
    const response = await fetch(`/api/course/course/${coid}/resourcecount`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const counts = await response.json();
    return counts;
  } catch (e) {
    console.log(e.message);
    dfmAlert(
      "There was an error retrieving the resource count for this course.",
    );
  }
}
